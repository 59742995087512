

$(document).ready(function () {

    //hoofdmenu vars
    var menu = $('#main-menu');
    var menuToggle = $('.hamburger');
    var headerHeight = $('.header').height();

    //hoofdmenu toggle
    $(menuToggle).click(function () {
        $(menu).slideToggle("slow", function () {
        });
    });

    $("#ruimtes_link p a").click(function () {
        $('html,body').animate({
            scrollTop: $(".content_outer").offset().top
        }, 'slow');
    });

    $('.google-review-slider').owlCarousel({
        loop:true,
        margin:10,
        nav:true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:3
            },
        },
        navText : ['<span id="google-review-slider__back-button"></span>','<span id="google-review-slider__forward-button"></span>']
    });
    $('.bard-image-slider').owlCarousel({
        loop: true,
        autoplay: true,
        autoplaySpeed:10000,
        items:1,
        nav: true,
        margin: 10
    });
    // Show all gallery images button
    $('#show_gallery').click(function () {
        $('.gallery-partial .col-sm-4').fadeIn();
        $(this).fadeOut();
    });


    orderProducts();
    initNotification();
});

//bestelformulier producten in formulierveld zetten
function orderProducts() {
    $('.bestellen_assortiment input[type=number]').each(function (index, elem) {
        value = $(elem).val();
        category = $(elem).next('input[type=text]').val();
        console.log(category);
        product = $(elem).parent().next('.omschrijving').find('.naam').text();
        formInput = $('input#producten');

        if (value > 0) {
            $(formInput).val($(formInput).val() + value + " x " + product + "(" + category + ")" + ", ");
        }
    });


    //RUIMTES slider
    var slider = $('.carousel.slide');
    $(slider).carousel({
        interval: 3000
    });
    //Handles the carousel thumbnails
    $('[id^=carousel-selector-]').click(function () {
        var id_selector = $(this).attr("id");
        try {
            var id = /-(\d+)$/.exec(id_selector)[1];
            jQuery(slider).carousel(parseInt(id));
        } catch (e) {
            console.log('Regex failed!', e);
        }
    });
}

function initNotification(){
    let notificationContent = $("#notification");
    if (notificationContent.length > 0){
        if (!isCookieSet('napkins_notification')){
            var notification = notificationContent.html();
            swal({
                html: notification,
                width: 600,
                onClose: function(){
                    hideSiteNotification();
                }
            });
        }
    }
}

function hideSiteNotification() {
    setCookie('napkins_notification', true, 3);
}

function setCookie(key, value, expireDays) {
    var date = new Date();
    date.setTime(date.getTime() + (expireDays * 24 * 60 * 60 * 1000));
    document.cookie = key + "=" + value + ";expires=" + date.toUTCString() + ";path=/";
}

function isCookieSet(key, value) {
    if (value === null || typeof value === 'undefined') {
        return !!document.cookie.split(';').filter(function(item) {
            return item.indexOf(key + '=') >= 0
        }).length;
    }

    return !!document.cookie.split(';').filter(function(item) {
        return item.indexOf(key + '=' + value) >= 0
    }).length;
}
